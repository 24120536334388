.fullscreen-close {
background-color: $primary;	
}

 .navbar-expand-lg .navbar-brand {
    width: auto;
 }
 
 .navbar-expand-lg .navbar-nav .nav-item:last-child a{
	padding-right: 0; 
 }

 .navbar-brand {
 	.text {
		font-size: 2rem;
		font-family: 'Barlow', sans-serif;
		font-weight: 500;
	}
	.ani-text {
		font-size: 2rem;
		font-family: 'Barlow', sans-serif;
		font-weight: 500;
	}
 }
 
 @media (max-width: 992px){
 	.navbar-expand-lg .navbar-brand {
    width: 9rem;
 }
 	.text {
		font-size: 1.2rem !important;
		font-family: 'Barlow', sans-serif;
		font-weight: 500;
	}
	.ani-text {
		font-size: 1.5rem !important;
		font-family: 'Barlow', sans-serif;
		font-weight: 500;
	}
}

@media (max-width: 992px){
header.header-absolute-top .navbar {
	max-width: 100% !important
}
}

header:not(.scroll-up):not(.is-sticky) .navbar-light.navbar-link-white .navbar-nav .nav-link {
	color: $white;
}

 @media (max-width: 992px){
  .navbar-expand-lg.navbar.navbar-light .navbar-collapse {
    background-color: #343a4f;
    box-shadow: none;
	max-height: 90vh;
	overflow-y: auto;
	margin-top: 0.5rem;
	border-bottom-left-radius: 0rem;
	border-bottom-right-radius: 0rem;
  }
 }
 
 .pushy {
 	position: fixed;
	width: 200px !important;
	height: 100%;
	top: 0;
	z-index: 9999;
	background: $primary !important;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
 	ul {
 		margin: 30px 0px 0px 0px !important;
 		padding-left: 0;
 	}
 	ul > li {
 		list-style-type: none;
 		font-size: 1.4rem;
 		a{
 			display: block;
			color: #ffffff;
			padding: 10px 5px;
			text-decoration: none;
			text-transform: uppercase;
 		}
 	}
 }
 
 .logo {
	margin-left: 40px;
	width: 60px;
 }