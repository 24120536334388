/* barlow-condensed-regular - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-regular.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-500 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-500.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-600 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-600.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-700 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-condensed-v5-latin/barlow-condensed-v5-latin-700.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}

/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/barlow-v5-latin/barlow-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-v5-latin/barlow-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-regular.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-500 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../fonts/barlow-v5-latin/barlow-v5-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-v5-latin/barlow-v5-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-500.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-600 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../fonts/barlow-v5-latin/barlow-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-v5-latin/barlow-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-600.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-700 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/barlow-v5-latin/barlow-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/barlow-v5-latin/barlow-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow-v5-latin/barlow-v5-latin-700.svg#Barlow') format('svg'); /* Legacy iOS */
}


$bg: #10111f;
$barsize: 15px;


$primary: #ff2d2f;
$secondary: #102E52;
$success: #3e8d63;
$info: #13101c;
$warning: #945707;
$danger: #d62518;
$light: #f8f9fa;
$dark: #343a40;
$gray: #e9e9e9;
$black: #000000;
$dark-gray: #343a4f;

$white: #ffffff;
$red: #D90506;
$darkblue: #102E52;
$lightgray: #EFEFEF;

$primary: $primary !default;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
);

$base-font: 'Barlow Condensed', sans-serif;

$accordion-icon-color: #ffffff; // your new color code
$accordion-icon-active-color: #ffffff; // your new color code
