.btn.draw-border {
	border-radius: 0;
}

.draw-border {
  box-shadow: inset 0 0 0 3px $dark-gray;
  color: $dark-gray;
  transition: color 0.25s 0.0833333333s;
  position: relative;
}
.draw-border::before, .draw-border::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}
.draw-border::before {
  border-bottom-width: 3px;
  border-left-width: 3px;
}
.draw-border::after {
  border-top-width: 3px;
  border-right-width: 3px;
}
.draw-border:hover {
  color: $primary;
}
.draw-border:hover::before, .draw-border:hover::after {
  border-color: $primary;
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}
.draw-border:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}
.draw-border:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.btn.draw-border {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font-family: $base-font;
  padding: 0.9em 1.9em;
  letter-spacing: 0.02rem;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1rem;
}
.btn:focus {
  outline: 2px dotted $dark-gray;;
}