.kontakt{
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 1.2rem
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 1.2rem
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 1.2rem
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 1.2rem
}
.form-control:focus {
	border-color: $primary;
	box-shadow: none;
}
.form-control {
	font-size: 1.2rem;
}
.form-control.invalid {
	background-color: rgba(255, 0, 0, 0.13);
}
}