#trendform_logo{
    width: 100%;
    height: 280px;
}

#trendform_logo .scroll_container{
    position:absolute;
    flex-direction: column;
}

#trendform_logo,
#trendform_logo :is(.scroll_container,.inner_container,svg){
    display: flex-start;
    align-items: center;
}

#trendform_logo svg{
    width: 260px !important;
    height: 260px !important;
    fill: none !important;
}