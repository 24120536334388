.oh-cookies {
        position: fixed;
        font-size: 20px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20000;
    }
    
    .oh-cookies__background {
        background: $dark;
        width: 100%;
        height: 100%;
        opacity: 0.8;
    }
    
  .oh-cookies__box {
        width: 18em;
        box-sizing: border-box;
        background: $primary;
        position: absolute;
        top: 50%;
        left: 50%;
        box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.4);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        padding: 1.5em;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    
    .oh-cookies__box .oh-cookies__title {
        color: #fff;
        font: normal 700 2.75em/1.1em $base-font;
        margin: 0 0 0.25em 0;
        text-align: left;
        text-transform: uppercase;
    }
    
    .oh-cookies__box p {
        color: #e9e9e9;
        font: normal 300 1.0em/1.5em $base-font;
        text-align: left;
        margin: 0 0 1.5em
    }
    
    .oh-cookies__box a.oh-cookies__ok {
        border: 2px solid $light;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        color: $light;
        display: block;
        font: normal 400 1em/1.1em $base-font;
        letter-spacing: 0.1em;
        padding: 0.75em 1.5em;
        margin: 0 0 1em 0;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        -webkit-transition: background 0.2s ease, color 0.2s ease;
        -moz-transition: background 0.2s ease, color 0.2s ease;
        transition: background 0.2s ease, color 0.2s ease;
    }
    
    html:not(.mobile):not(.tablet) .oh-cookies__box a.oh-cookies__ok:hover {
        background: #ffffff;
        color: #222222;
    }
    
    .oh-cookies__box a.oh-cookies__more {
        color: $light;
        display: block;
        font: normal 300 0.7em/1.1em $base-font;
        letter-spacing: 0.15em;
        padding: 0.25em 0;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        -webkit-transition: background 0.2s ease, color 0.2s ease;
        -moz-transition: background 0.2s ease, color 0.2s ease;
        transition: background 0.2s ease, color 0.2s ease;
    }
    
    @media screen and (max-width: 1200px) {
    
        .oh-cookies {
            font-size: 18px;
        }
    
    }
    
    @media screen and (max-width: 800px) {
    
        .oh-cookies {
            font-size: 16px;
        }
    
    }
    
    @media screen and (max-width: 400px) {
    
        .oh-cookies {
            font-size: 14px;
        }
    
    }