@media (max-width:320px){
	.subheader {
	h1 {
		font-size: 3.0rem !important;
		line-height: 3rem;
	}
}
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
	.subheader {
	h1 {
		font-size: 3.5rem !important;
		line-height: 3.5rem;
	}
}
}

@media only screen and (min-width: 376px) and (max-width: 414px) {
	.subheader {
	h1 {
		font-size: 3.7rem !important;
		line-height: 3.7rem;
	}
}
}

@media only screen and (min-width: 415px) and (max-width: 768px) {
	.subheader {
	h1 {
		font-size: 5rem !important;
		line-height: 5rem;
	}
}
}

@media only screen and (min-width: 769px) and (max-width: 1113px) {
	.subheader {
	h1 {
		font-size: 6rem !important;
		line-height: 5.5rem;
	}
}
}

@media only screen and (min-width: 1114px) and (max-width: 2800px) {
	.subheader {
	h1 {
		font-size: 7.5rem !important;
		line-height: 7.5rem;
	}
}
}