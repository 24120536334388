.case-study {
	ul > li {
		color: $dark;
	}
	a{
		color: $dark;
		}
		a:hover{
		color: $primary;
		}
		.badge {
			font-size: 0.95rem;
		}
}