@media (max-width: 414px){
	.hello {
	height: auto !important;
	h1 {
		font-size: 4rem !important;
		line-height: 4rem;
	}
}
}

@media only screen and (min-width: 415px) and (max-width: 768px) {
	.hello {
	height: 100vh !important;
	h1 {
		font-size: 5rem !important;
		line-height: 5rem;
	}
}
}

@media only screen and (min-width: 769px) and (max-width: 1113px) {
	.hello {
	height: 100vh !important;	
	h1 {
		font-size: 6rem !important;
		line-height: 5.5rem;
	}
}
}

@media only screen and (min-width: 1114px) and (max-width: 2800px) {
	.hello {
	height: 100vh !important;	
	h1 {
		font-size: 8rem !important;
		line-height: 7.5rem;
	}
}
}