.tf-accordion {
    .accordion-button{
        font-size: inherit;
        background-color: $dark-gray;
        color: $white;
    }
    .accordion-button:not(.collapsed){
        color: $white;
        box-shadow: none;
        background-color: $dark-gray;
    }
    .accordion-body{
        background-color: $light;
    }
    p{
        font-size: 1.3rem;
    }
    ul > li{
        color: $black;
    }
    .accordion-button:not(.collapsed)::after, .accordion-button::after{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
}