@import "variables";
@import "button";
@import "cookie";
@import "hello";
@import "agentur";
@import "navbar";
@import "hr";
@import "case-study";
@import "subheader";
@import "form";
@import "fontawesome/fontawesome";
@import "fontawesome/brands";
@import "tf-animation";
@import "pushy";
@import "accordion";

body {
	max-width: 2500px;
}


h1, h2, h3, h4, h5, h6, p, span, li, a {
	font-family: $base-font;
}

p {
	font-size: 1.7rem;
	color: $black;
	line-height: 2.2rem;
}

@media (max-width: 767px){
p {
	font-size: 1.4rem;
	color: $black;
	line-height: 2.2rem;
}
.expertise ul > li, .referenzen ul > li, .projekte ul > li{
	font-size: 1.4rem !important;
}
.grid-separator > [class*="col-"], .grid-separator [class^="col-"]{
padding: 0.1rem;
}
}

a {
	color: $primary;
}


a:hover {
	color: $primary;
}

.small {
	font-size: 1.3rem;
	line-height: 1.75rem;
}

h1, .h1 {
	font-size: 4.5rem;
}

.bg-dark {
	background-color: $dark;
}

.bg-gray {
	background-color: $gray;
}

.bg-dark-gray {
	background-color: $dark-gray;
}

.bg-red {
	background-color: $primary;
}

.text-dark {
	color: $dark;
}

.text-gray {
	color: $gray;
}

.text-dark-gray {
	color: $dark-gray;
}

.text-red {
	color: $primary;
}

.btn-outline-light:hover{
	background-color: $primary;
	color: $light;
}

.skewed {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transform: skewY(-2deg);
  transform-origin: top left;
}

ul > li {
	font-size: 1.3rem;
	color: $dark;
}

.expertise, .referenzen, .projekte {
ul > li {
	font-size: 1.3rem;
	color: $light;
}
}

.slick-dots li button:before{
	font-size: 35px !important;
	margin-top: 20px;
}

.slick-dots li.slick-active button:before {
	color: $primary !important;
	opacity: 1 !important;
}

.slick-slider {
    margin:0 -15px;
}

@media (max-width: 414px){
.slick-slider {
    margin:0
}

}

.slick-slide {
    padding:10px;
	text-align:center;
    margin-right:15px;
    margin-left:15px;
}

.btn-tf {
	border-radius: 0;
	border: 3px solid $primary;
	font-size: 1.4rem;
	font-weight: 500;
	text-transform: none;
	color: $primary;
	padding: 5px 13px;
}


.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

.fullscreen-bg__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

section.vid {
  position: relative;
  background-color: black;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

section.vid video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
  z-index: 0;
  object-fit: cover;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

section.vid .container {
  position: relative;
  z-index: 2;
}

section.vid .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

/* Media Query for devices withi coarse pointers and no hover functionality */

/* This will use a fallback image instead of a video for devices that commonly do not support the HTML5 video element */

@media (pointer: coarse) and (hover: none) {
  section.vid {
    background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
  }

  section.vid video {
    display: none;
  }
}

img.client-logo{
  width: 277px;
  height: auto;
}

img.img-portfolio{
  width: 100%;
  height: auto;
}

img.img-thumb{
  width: 100%;
  height: auto
}